import * as React from "react"
import { Hero } from "../components/Hero/Hero"
import SEO from "../components/SEO"
import heroImage from '../images/hero/services.jpg'
import kSammels from '../images/avatar/kSammels.jpg'
import nEllis from '../images/avatar/nEllis.jpg'
import linkedIn from '../images/linkedin.png'
import '../styles.scss'

const WhoPage = () => (
  <>
    <SEO title="Who" />
    <Hero page='who' backgroundImage={heroImage}>
      <div className='container height-100'>
        <div className='headline'>
          <h2 className="text-blue-desktop">
            Big Experience<br />
            Big Brands<br />
          </h2>
        </div>
        <div className='container-blue content-70 height-100 float-right'>
          <div className='container-content profile'>
            <div>
              <img src={kSammels} className='avatar' alt='Keith Sammels' />
              <a href='http://linkedin.com/in/keith-j-sammels-fcim-5539b4146' target='_blank' rel="noreferrer">
                <img src={linkedIn} alt='LinkedIn profile' width='75' style={{ marginTop: '1rem' }} />
              </a>
            </div>
            <div className='profile-content'>
              <p className='heading'>Keith Sammels - an accountable creative influence with the skill to quickly understand and solve the most challenging sales and marketing conundrums.</p>
              <p>Keith Sammels has been a CEO within an $850 million international marketing communications group.  He has created marketing strategies for some of the world’s largest companies and won countless awards for his clients.</p>
              <p>He was part of the management team responsible for the successful offer for sale by Kleinwort Benson Ltd for Lopx plc.  In addition, Keith has helped to build three thriving advertising agencies of all which have been successfully sold to London communication groups.  The latest of which is the very excellent LAW Creative acquired by Selbey Anderson in 2020.</p>
              <p>Keith was for two years a non-executive director for a sports charity is currently a director of Montana Concept. He is also a proud mentor for the University of Hertfordshire.</p>
              <p>At Montana, Keith is available for consultancy, business development, content, creative integrated marketing communications and business mentoring.</p>
              <hr />
            </div>
          </div>
          <div className='container-content profile'>
            <div>
              <img src={nEllis} className='avatar' alt='Nicola Sammels' />
            </div>
            <div>
              <p className='heading'>Nicola Sammels is a prolific project director with the ability to bring strategy order and success to any marketing task</p>
              <p>Hugely respected for her work with some of the largest hotel, leisure and retail groups in the world Nicola has been consistently successful in delivering award-winning marketing campaigns. Her style is calm, inclusive, focused, analytical and intuitive. Nicola has held positions with the Department of Trade & Industry, worked within the telecommunications industry and held Account Director and Managing Director posts within the advertising industry. Including time at the Lopex plc owned Alliance International in their London offices and being a founding director of lawcreative.co.uk.</p>
              <p>Nicola’s experience is specifically in integrated marketing campaigns for hotels, health & fitness, gaming, restaurants, food & beverage, tourism, chemicals and catering.</p>
              <p>At Montana, Nicola is available to discuss short to medium term project support and management tasks.</p>
            </div>
          </div>
        </div>
      </div>
    </Hero>
  </>
)

export default WhoPage